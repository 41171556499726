import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { IHSRRelic } from '../../../common/model/graphql-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';

import './hsr-relic-set.scss';
import { Accordion } from 'react-bootstrap';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

interface IProps {
  id?: string;
  name: string | number;
  compactMode?: boolean;
  unitId?: string;
  number?: number;
  comment?: string;
  smol?: boolean;
}

export const HSRRelicSet: React.FC<IProps> = ({
  id,
  unitId,
  name,
  compactMode,
  number,
  comment,
  smol
}) => {
  const allHSRRelics = useStaticQuery(graphql`
    query {
      allContentfulHsrRelics {
        nodes {
          ...HsrRelicsFieldsFragment
        }
      }
    }
  `);
  if (!id && !name && !unitId) {
    return <div>Err</div>;
  }

  const relic = allHSRRelics.allContentfulHsrRelics.nodes.find(
    (emp: IHSRRelic) =>
      id ? emp.id === id : unitId ? emp.relicId === unitId : emp.name === name
  );

  if (!relic) {
    return <div>Err</div>;
  }

  const image = relic.image
    ? getImage(relic.image.localFile.childImageSharp)
    : null;

  return (
    <>
      {compactMode ? (
        <>
          {smol ? (
            <>
              <div className={`hsr-item-inline`}>
                <div>
                  <div className={`hsr-image`}>
                    {image && (
                      <GatsbyImage
                        className="disable-transition"
                        image={image}
                        alt={`${relic.name}`}
                      />
                    )}
                  </div>
                  <div className="hsr-name">
                    <span>{relic.name}</span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    {number && <span className="cone-priority">{number}</span>}
                    <div className={`hsr-set-image rarity-3`}>
                      {image && (
                        <GatsbyImage
                          className="disable-transition"
                          image={image}
                          alt={`${relic.name}`}
                        />
                      )}
                    </div>
                    {relic.name}{' '}
                    {comment === '2PC' &&
                      relic.name === 'Pioneer Diver of Dead Waters' && (
                        <span className="red">(1 debuff required)</span>
                      )}
                    {comment === '4PC' &&
                      relic.name === 'Pioneer Diver of Dead Waters' && (
                        <span className="red">(3 debuffs required)</span>
                      )}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="hsr-set-content">
                      <div className={`hsr-set-description ${relic.element}`}>
                        <div>
                          <span className="set-piece">(2)</span>{' '}
                          {renderRichText(relic.bonus2, options)}
                        </div>
                        {relic.bonus4 && (
                          <div>
                            <span className="set-piece">(4)</span>{' '}
                            {renderRichText(relic.bonus4, options)}
                          </div>
                        )}
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </>
          )}
        </>
      ) : (
        <>
          <div className="hsr-relic-box box">
            <div className="hsr-relic-header">
              <div className={`hsr-relic-image ${relic.type}`}>
                {image && (
                  <GatsbyImage
                    className="disable-transition"
                    image={image}
                    alt={`${relic.name}`}
                  />
                )}
              </div>
              <div className="hsr-relic-data">
                <h4>{relic.name}</h4>
                <div className="hsr-relic-info">
                  <p>
                    Type: <strong>{relic.type}</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="hsr-relic-content">
              <div className={`hsr-set-description ${relic.element}`}>
                <div>
                  <span className="set-piece">(2)</span>{' '}
                  {renderRichText(relic.bonus2, options)}
                </div>
                {relic.bonus4 && (
                  <div>
                    <span className="set-piece">(4)</span>{' '}
                    {renderRichText(relic.bonus4, options)}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
